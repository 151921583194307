import React from 'react';
import styles from './Header.module.css';
import logo from '../assets/puma.png'; 

const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Team Logo" className={styles.logo} />
          <h1 className={styles.title}>Pumy Betonu</h1>
        </div>
        <nav className={styles.nav}>
          <ul>
            <li><a onClick={() => scrollToSection('news')} href="#news">News</a></li>
            <li><a onClick={() => scrollToSection('team')} href="#team">Team</a></li>
            <li><a onClick={() => scrollToSection('contact')} href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;