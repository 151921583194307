import React from 'react';
import styles from './TeamSection.module.css';

const TeamSection = () => {
  const teamMembers = [
    { name: 'Kacper Szymczak', position: 'Guard (C)', image: '/players/kac.png', status: 'active', quote: "Basketball is my favorite sport."},
    { name: 'Jan Mydłowski', position: 'Forward', image: '/players/jan.png', status: 'active', quote: "Elevated objectives aren't attained via straightforward determinations." },
    { name: 'Robert Boneczko', position: 'Guard', image: '/players/rob.png', status: 'active', quote: "The game gave me so much, I am in debt forever." },
    { name: 'Benedykt Mydłowski', position: 'Forward', image: '/players/ben.png', status: 'active', quote: "Scared of nothing, ready for everything" },
    { name: 'Paweł Kopiec', position: 'Forward', image: '/players/paw.png', status: 'active', quote: "I just came to hoop." },
    { name: 'Mateusz Bielawski', position: 'Guard', image: '/players/mat.png', status: 'active', quote: "You miss 100% of shots you don't take." },
    { name: 'Michał Nowakowski', position: 'Guard', image: '/players/mic.png', status: 'active', quote: "Never let them know your next move." },
    { name: 'Łukasz Jaworowski', position: 'Forward', image: '/players/luk.png', status: 'active', quote: "Are we talking about practice?" },
    { name: 'Artur Czerwiński', position: 'Guard', image: '/players/art.png', status: 'active', quote: "With great power comes great responsibility." },
    { name: 'Aleksander Czaplicki', position: 'Center', image: '/players/ale.png', status: 'active', quote: "Be like concrete my friend." },
    { name: 'Jakub Olechny', position: 'Forward', image: '/players/jak.png', status: 'active', quote: "Job’s not finished. Job finished? No, I don’t think so." },
    { name: 'Olgierd Korycki', position: 'Guard', image: '/players/olg.png', status: 'active', quote: "'Till u fight u are a winner." },
    { name: 'Damian Tziamtzis', position: 'Guard', image: '/players/dam.png', status: 'retired', quote: "Splash!" },
    { name: 'Samuel L. Kapner', position: 'Center', image: '/players/sam.png', status: 'retired', quote: "You shouldn't ball to break from work, but treat work as a break from Ball. Ball is life." }
  ];

  return (
    <div className={styles.teamSection}>
      {teamMembers.map((member, index) => (
        <div key={index} className={styles.teamMember}>
          <div className={styles.cardInner}>
            <div className={styles.cardFront}>
              <div className={styles.imageContainer}>
                <img src={member.image} alt={member.name} className={styles.memberImage} />
                <span className={`${styles.statusIndicator} ${styles[member.status]}`}></span>
              </div>
              <h3>{member.name}</h3>
              <p>{member.position}</p>
              <p className={styles.status}>{member.status === 'active' ? 'Active' : 'Retired'}</p>
            </div>
            <div className={styles.cardBack}>
            <img src="/puma.png" alt="Logo" className={styles.backLogo} />
              <p className={styles.quote}>"{member.quote}"</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamSection;