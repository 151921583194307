import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import NewsList from './components/NewsList';
import TeamSection from './components/TeamSection';
import ContactForm from './components/ContactForm';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        {/* <section id="news">
          <h2>Latest News</h2>
          <NewsList />
        </section> */}
        <section id="team">
          <h2>Our Team</h2>
          <TeamSection />
        </section>
        <section id="contact">
          <h2>Contact Us</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ContactForm />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default App;